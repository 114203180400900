<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { putAction, getAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'

export default {
  data() {
    return {
      ...api.command.getState(),
      detail: {
        farmNativeProductStandardsVOS: [],
        upDown: '',
        salePrice: null,
        vipPrice: null,
        canBuy: '1',
        deliveryType: [],
        nativePickupList: []
      },
      storeData: [],
      mallData: [],
      freightData: [],
      thirdProductData: [],
      thirdBatchData: [],
      treeData: [],
      goodsLocation: [],
      isShowSnapshot: false
    }
  },
  watch: {
    'detail.linkShopId'(a, b) {
      if (a) {
        this.thirdProductSearch()
        this.getBaseDataInit(a)
        this.getMainBody(a)
      }
    },
    'detail.thirdProductId'(a, b) {
      if (a) {
        this.thirdBatchSearch()
      }
    }
    // 'detail.farmNativeProductStandardsVOS': {
    //   handler(a, b) {
    //     console.log(a, b)
    //   },
    //   deep: true
    // }
  },
  mounted() {
    const { id } = this.$route.query
    // 获取详情
    id &&
      api.command.getDetail.call(this, { url: `/commodity/farmNativeProduct/${id}` }).then(result => {
        let arr = result.farmNativeProductStandardsVOS ?? []
        this.detail.farmNativeProductStandardsVOS = arr.map(e => {
          return apiTool.getVipPrice(e)
        })
        //   this.detail.nativePickupList = result.nativePickupList.map(
        //     e => e.pickupId
        //   )
        this.detail.deliveryType = result.deliveryType ? result.deliveryType.split(',') : ['0', '1']
      })

    this.storeSearch()
    this.mallSearch()
    this.freightSearch()

    getAction('/api/config/farmGoodsCategory/listTree').then(res => {
      if (res.code == 200) {
        this.treeData = res.data
      } else {
        this.$message.warning(res.msg)
      }
    })
    // getAction('/api/base/farmPickupAddress/list').then(res => {
    //   if (res.code == 200) {
    //     let arr = res.data
    //     this.goodsLocation = arr.map(e => {
    //       return {
    //         ...e,
    //         value: e.id
    //       }
    //     })
    //   } else {
    //     this.$message.warning(res.msg)
    //   }
    // })
  },
  methods: {
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorList?shopId=${id}`).then(e => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    async mallSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/config/farmGoodsCategory/list?name=${value}`
      })
      this.mallData = searchResult.map(el => {
        return {
          name: el.name,
          value: el.id
        }
      })
    },
    async thirdProductSearch() {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/queryProduct?shopId=${this.detail.linkShopId}`
      })
      this.thirdProductData = searchResult.map(el => {
        return {
          ...el,
          name: el.name,
          value: `${el.id}`
        }
      })
    },
    async thirdBatchSearch() {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/queryBatch?productId=${this.detail.thirdProductId}`
      })

      this.thirdBatchData = searchResult.map(el => {
        return {
          ...el,
          name: el.name,
          value: `${el.id}`
        }
      })
    },
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`
      })
      this.storeData = searchResult.map(el => {
        return {
          name: el.mainBodyName,
          value: el.id
        }
      })
    },
    async freightSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/config/farmFreight/list?name=${value}`
      })
      this.freightData = searchResult.map(el => {
        return {
          name: el.name,
          value: el.id
        }
      })
    },
    getVipPic(pic1, pic2, index) {
      let price = Math.round((pic2 + (index * (pic1 - pic2)) / 6) * 100) / 100
      return price
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '特产名称(76字符内)',
            type: 'input',
            cols: 12,
            key: 'nativeProductName',
            props: {
              maxLength: 76
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'linkShop',
            onInputSearch: this.storeSearch,
            valueKey: 'linkShopId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '分类',
            type: 'treeSelect',
            cols: 12,
            key: 'goodsTypeId',
            typeData: this.treeData,
            props: {
              placeholder: '请选择分类'
            },
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '商品目录',
            type: 'dropDownInput',
            cols: 12,
            key: 'categories',
            onInputSearch: this.mallSearch,
            valueKey: 'categoriesId',
            inputProps: {
              placeholder: '搜索商品分类',
              addonBefore: <a-icon type="search" />
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: 'Banner选择',
            type: 'radioButton',
            key: 'bannerType',
            cols: 12,
            defaultValue: '0',
            typeData: [
              {
                name: 'banner图片',
                value: '0',
                display: true
              },
              {
                name: '视频地址',
                value: '2',
                display: true
              },
              {
                name: '数字基地监控视频',
                value: '1',
                display: this.isShowSnapshot
              }
            ].filter(e => e.display),
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '运费模板',
            type: 'row',
            cols: 12,
            gutter: 3,
            children: [
              {
                type: 'select',
                cols: 16,
                placeholder: '请选择运费模板',
                key: 'freightTemplateId',
                typeData: this.freightData
              },
              {
                type: 'button',
                cols: 4,
                label: '编辑运费模板',
                props: {
                  style: {
                    margintTop: '10px'
                  },
                  on: {
                    click: () => {
                      this.editYFmodule()
                      //   this.$router.push('/platform/freight')
                    }
                  }
                }
              },
              {
                cols: 4,
                type: 'button',
                label: '刷新运费模板',
                props: {
                  style: {
                    margintTop: '10px'
                  },
                  on: {
                    click: () => this.freightSearch()
                  }
                }
              }
            ]
          },
          {
            name: '开放购买（默认选择是）',
            type: 'radioButton',
            key: 'canBuy',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '周边推荐（默认选择否）',
            type: 'radioButton',
            key: 'recommend',
            defaultValue: '0',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          }
        ]
      }
    },
    // getForm1Copy() {
    //   return {
    //     title: '配送',
    //     type: 'cardForm',
    //     data: [
    //       {
    //         name: '配送方式',
    //         type: 'checkBoxButton',
    //         key: 'deliveryType',
    //         cols: 12,
    //         display: true,
    //         typeData: [
    //           {
    //             name: '物流配送',
    //             value: '0'
    //           },
    //           {
    //             name: '农业局提货点',
    //             value: '1'
    //           }
    //         ],
    //         rules: [
    //           {
    //             required: true,
    //             type: 'array'
    //           }
    //         ]
    //       },
    //       {
    //         name: '运费模板',
    //         type: 'row',
    //         cols: 12,
    //         display: this.detail.deliveryType.includes('0'),
    //         gutter: 3,
    //         children: [
    //           {
    //             type: 'select',
    //             cols: 16,
    //             placeholder: '请选择运费模板',
    //             key: 'freightTemplateId',
    //             typeData: this.freightData
    //           },
    //           {
    //             type: 'button',
    //             cols: 4,
    //             label: '编辑运费模板',
    //             props: {
    //               style: {
    //                 margintTop: '10px'
    //               },
    //               on: {
    //                 click: () => {
    //                   this.editYFmodule()
    //                 }
    //               }
    //             }
    //           },
    //           {
    //             cols: 4,
    //             type: 'button',
    //             label: '刷新运费模板',
    //             props: {
    //               style: {
    //                 margintTop: '10px'
    //               },
    //               on: {
    //                 click: () => this.freightSearch()
    //               }
    //             }
    //           }
    //         ]
    //       },
    //       {
    //         name: '添加农业局提货点',
    //         type: 'select',
    //         cols: 12,
    //         display: this.detail.deliveryType.includes('1'),
    //         props: {
    //           mode: 'multiple'
    //         },
    //         rules: [
    //           {
    //             required: true,
    //             type: 'array'
    //           }
    //         ],
    //         key: 'nativePickupList',
    //         typeData: this.goodsLocation
    //       }
    //     ].filter(e => e.display)
    //   }
    // },
    editYFmodule() {
      let a = document.createElement('a')
      a.setAttribute('href', '/platform/freight')
      a.setAttribute('target', '_blank')
      a.setAttribute('id', 'startTelMedicine')
      // 防止反复添加
      if (document.getElementById('startTelMedicine')) {
        document.body.removeChild(document.getElementById('startTelMedicine'))
      }
      document.body.appendChild(a)
      a.click()
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: 'Banner （尺寸750*750PX）限制1张',
        data: [
          {
            type: 'upload',
            key: 'bannerUrl',
            maxLength: 1,
            cols: 24,
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm2Copy() {
      return {
        type: 'tabs',
        key: 'selectType',
        children: [
          {
            title: '宣传视频',
            data: [
              {
                type: 'uploadViedo',
                key: 'videoUrl',
                maxLength: 1,
                cols: 24
              }
            ]
          },
          this.isShowSnapshot ? this.getBaseDataInit(this.detail.linkShopId) : []
        ]
      }
    },
    getBaseDataInit(shopId = null) {
      return [
        {
          title: '数字基地监控视频',
          data: [
            {
              type: 'baseUpload',
              key: 'monitorCode',
              maxLength: 1,
              cols: 24,
              shopId: shopId,
              setValue: this.detail.monitorCode,
              setShowValue: this.detail.showMonitors,
              setFirm: false
            }
          ]
        }
      ]
    },
    getForm5() {
      return {
        title: '农产品追溯—关联',
        type: 'cardForm',
        data: [
          {
            name: '产品名称',
            type: 'select',
            cols: 12,
            key: 'thirdProductId',
            typeData: this.thirdProductData,
            props: {
              placeholder: '选择产品名称'
              //   showSearch: true
            }
          },
          {
            name: '产品批次号',
            type: 'select',
            cols: 12,
            key: 'thirdBatchId',
            typeData: this.thirdBatchData,
            props: {
              placeholder: '产品批次号'
              //   showSearch: true
            }
          }
        ]
      }
    },
    getForm3() {
      let that = this
      return {
        type: 'table',
        showFootButton: true,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddData: () => {
          return {
            standardsName: null,
            stockNum: null,
            salePrice: null,
            vipPrice: null,
            v1: '-',
            v2: '-',
            v3: '-',
            v4: '-',
            v5: '-',
            v6: '-'
          }
        },
        columns: [
          {
            dataIndex: 'standardsName',
            align: 'left',
            title: <div style={{ marginLeft: '20px' }}>产品规格</div>,
            width: 200,
            customRender: function(text, records, index) {
              const onInput = data => {
                records.standardsName = data.target.value
              }
              return records.isEdit ? (
                <a-input disabled={that.detail.canBuy == '0'} placeholder="产品规格" onChange={onInput} value={text} />
              ) : (
                <div style={{ marginLeft: '20px' }}>{text}</div>
              )
            }
          },
          {
            dataIndex: 'stockNum',
            align: 'left',
            title: '库存',
            width: 100,
            customRender: function(text, records, index) {
              const onInput = data => {
                records.stockNum = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="库存数量"
                  onChange={onInput}
                  value={text}
                />
              ) : (
                <div>{text}</div>
              )
            }
          },
          {
            dataIndex: 'salePrice',
            align: 'left',
            title: '现价',
            width: 100,
            customRender: function(text, records) {
              const onInput = data => {
                records.salePrice = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  addon-before="￥"
                  placeholder="价格"
                  onChange={onInput}
                  value={text}
                />
              ) : (
                <div>{text}</div>
              )
            }
          },
          {
            dataIndex: 'vipPrice',
            align: 'left',
            title: '会员最低价',
            width: 180,
            customRender: function(text, records) {
              const onInput = data => {
                records.vipPrice = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  value={text}
                  addon-before="￥"
                  placeholder="会员价（会员价必填且不能为0）"
                  onChange={onInput}
                />
              ) : (
                <div>{text}</div>
              )
            }
          },
          {
            dataIndex: 'v1',
            align: 'left',
            title: 'V1',
            width: 50
          },
          {
            dataIndex: 'v2',
            align: 'left',
            title: 'V2',
            width: 50
          },
          {
            dataIndex: 'v3',
            align: 'left',
            title: 'V3',
            width: 50
          },
          {
            dataIndex: 'v4',
            align: 'left',
            title: 'V4',
            width: 50
          },
          {
            dataIndex: 'v5',
            align: 'left',
            title: 'V5',
            width: 50
          },
          {
            dataIndex: 'v6',
            align: 'left',
            title: 'V6',
            width: 50
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: '10%',
            title: '操作',
            tableType: 'editDelete',
            onSave: (text, records) => {
              if (records.standardsName && records.stockNum >= 0 && records.salePrice > 0) {
                if (Number(records.salePrice) < Number(records.vipPrice)) {
                  this.$message.warning('会员价应低于现价！')
                } else if (records.vipPrice != '' && Number(records.vipPrice) == 0) {
                  this.$message.warning('会员价不能为零！')
                } else {
                  records.isEdit = false
                  apiTool.getVipPrice(records)
                }
              } else {
                this.$message.warning('请填入产品规格/库存（不能小于零）/现价（不能为零）')
              }
            }
          }
        ],
        dataSource: this.detail.farmNativeProductStandardsVOS
      }
    },
    getForm4() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            cols: 24
          }
        ]
      }
    },
    getChildrenRecursive({ arr, code }) {
      let result
      arr.forEach(item => {
        if (item[id] === code) {
          result = item
        } else {
          this.getChildrenRecursive({
            arr: item[children],
            code
          })
        }
      })
      return result
    },
    goodsSendC(idList) {
      let arr = []
      this.goodsLocation.forEach(element => {
        if (idList.includes(element.id)) {
          arr.push({
            id: null,
            pickupId: element.id,
            pickupName: element.name,
            productId: null
          })
        }
      })
      return arr
    },

    getFoot() {
      let that = this
      const left = [
        {
          name: '提交',
          loading: this.loading,
          sumbit: true,
          type: 'primary',
          onClick: data => {
            // let nativePickupList =
            //   data.nativePickupList.length > 0
            //     ? this.goodsSendC(data.nativePickupList)
            //     : []
            let goodsTypeName = document.getElementsByClassName('ant-select-selection-selected-value')[0].innerHTML

            if (data.canBuy == 1) {
              if (this.detail.farmNativeProductStandardsVOS.length == 0) {
                this.$message.warning('请输入产品规格！')
                return
              } else {
                try {
                  this.detail.farmNativeProductStandardsVOS.forEach(element => {
                    if (element.isEdit) {
                      this.$message.warning('请先保存产品规格！')
                      throw new Error('结束！')
                    }
                  })
                } catch (error) {
                  console.log(error)
                  return
                }
              }
            }

            let monitorCode = null
            let monitorImg = null
            let showMonitors = []
            if (
              this.detail.monitorCode instanceof Array &&
              this.detail.bannerType == 1 &&
              this.detail.monitorCode.length > 0
            ) {
              monitorCode = (
                this.detail.monitorCode.find(e => e.select) || {
                  monitorCode: ''
                }
              ).monitorCode
              monitorImg = (
                this.detail.monitorCode.find(e => e.select) || {
                  monitorImg: ''
                }
              ).monitorImg
              this.detail.monitorCode.forEach(e => {
                if (e.isOpen) {
                  showMonitors.push(e.monitorCode)
                }
              })
            }

            let farmNativeProductStandardsDTOS = that.detail.farmNativeProductStandardsVOS.map(e => {
              return {
                id: e.id,
                salePrice: e.salePrice,
                standardsName: e.standardsName,
                stockNum: e.stockNum,
                vipPrice: e.vipPrice,
                nativeProductId: e.nativeProductId
              }
            })

            if (that.detail.freightTemplateId) {
              that.freightData.forEach(e => {
                if (e.value == that.detail.freightTemplateId) {
                  that.detail.freightTemplate = e.name
                }
              })
            }

            if (that.detail.thirdProductId) {
              that.thirdProductData.forEach(e => {
                if (e.value == that.detail.thirdProductId) {
                  that.detail.thirdProductName = e.name
                }
              })
            }

            let upDown = that.detail.id ? that.detail.upDown : '0'
            api.command[that.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/commodity/farmNativeProduct',
                params: {
                  ...that.detail,
                  farmNativeProductStandardsDTOS: farmNativeProductStandardsDTOS,
                  upDown: upDown,
                  monitorCode: monitorCode,
                  monitorImg: monitorImg,
                  showMonitors: showMonitors.toString(),
                  goodsTypeName: goodsTypeName,
                  deliveryType: null,
                  nativePickupList: null
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/commodityManagement/specialty')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/commodityManagement/specialty')
          }
        }
      ]
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            putAction(`/commodity/farmNativeProduct/${this.detail.upDown == 0 ? 'down' : 'up'}/${this.detail.id}`).then(
              e => {
                if (e.code == 200) {
                  this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                  setTimeout(() => {
                    this.$router.back()
                  }, 500)
                } else {
                  this.$message.error(e.msg)
                }
              }
            )
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/commodity/farmNativeProduct/${this.detail.id}`,
              isPost: false
            })

            setTimeout(() => {
              this.$router.back()
            }, 500)
          }
        }
      ]
      return {
        left,
        right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        typeData={{
          linkShop: this.storeData,
          categories: this.mallData
        }}
        foot={this.getFoot()}
        form={this.detail}
        data={[
          this.getForm1(),
          //   this.getForm1Copy(),
          this.getForm2(),
          this.getForm2Copy(),
          this.getForm5(),
          this.getForm3(),
          this.getForm4()
        ]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
